nav {
        

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    position: fixed;
    left: 2rem;
    top: 5rem;
    background: rgba(0, 0, 0, 0.3);
    padding: 1.5rem 1.5rem;
    z-index: 2;
    backdrop-filter: blur(15px); 
    border-radius: 3rem;
    width: max-content;
}
nav a {
    background: transparent;
    padding: 0.45rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    color: var(--color-primary);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}
nav a.active {
    background: var(--color-light);
    color: var(--color-white);
}

/*===========Media Queries (Med)=========*/

@media screen and (max-width: 900px) {
    nav {
        display: none;
    }

    
}

/*===========Media Queries (Sm)=========*/

@media screen and (max-width: 600px) {
    nav {
        display: none;
    }
}