.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
    margin-right: 2rem;
}

.about__me {
    width: 400px;
    height: 275px;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
        -webkit-border-radius: 45deg;
        -moz-border-radius: 45deg;
        -ms-border-radius: 45deg;
        -o-border-radius: 45deg;
    display: grid;
    place-items: center;
}

.about__me-image {
    width: auto;
    height: auto;
    border-radius: 2rem;
        -webkit-border-radius: 2rem;
        -moz-border-radius: 2rem;
        -ms-border-radius: 2rem;
        -o-border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
        -webkit-transform: rotate(10deg);
        -moz-transform: rotate(10deg);
        -ms-transform: rotate(10deg);
        -o-transform: rotate(10deg);
    transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
}

.about__me-image:hover {
    transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background-color: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
    padding: 2rem;
    text-align: center; }
    /* transition: var(--transition);
        -webkit-transition: var(--transition);
        -moz-transition: var(--transition);
        -ms-transition: var(--transition);
        -o-transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary);
    cursor: default;
} */

.about-icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    color: var(--color-light);
    margin: 2rem 0 3.6rem;
}

.about__words {
    width: 70%;
    display: grid;
    margin: 6rem auto 4rem;
}

/*============ Media Queries (Med)=========*/
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        justify-content: center;
    }

    .about__card {
        justify-self: center;
        width: 100%;
    }

    .about__me {
        width: 80%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }
}

/*============ Media Queries (sm)=========*/
@media screen and (max-width: 600px) {
    .about__me {
        width: 100%;
        margin: 3rem auto 3rem;
        display: none;
    }

    .about__me-image {
        display: none;
    }

    .about__container {
        margin-right: 0px;
        align-items: center;
    }

    .about__card {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0;
    }
}