@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 1.5s ease;
    --transition1: all .25s ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 95%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture.png);
    background-attachment: fixed;
}

/* ======General Styles=========*/
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

h2 {
    font-size: 1rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
    margin-bottom: 1rem;
}

a {
    color: var(--color-primary);
    transition: var(--transition);
        -webkit-transition: var(--transition);
        -moz-transition: var(--transition);
        -ms-transition: var(--transition);
        -o-transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    height: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: .75rem 1.2rem;
    border-radius: .4rem;
        -webkit-border-radius: .4rem;
        -moz-border-radius: .4rem;
        -ms-border-radius: .4rem;
        -o-border-radius: .4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
        -webkit-transition: var(--transition);
        -moz-transition: var(--transition);
        -ms-transition: var(--transition);
        -o-transition: var(--transition);
}

.btn:hover {
    background-color: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background-color: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    justify-content: center;
    object-fit: cover;
}


/*===========Media Queries (Med)=========*/

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }

    .nav {
        display: none;
    }
}

/*===========Media Queries (Sm)=========*/

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}
