.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
        -webkit-border-radius: 2rem;
        -moz-border-radius: 2rem;
        -ms-border-radius: 2rem;
        -o-border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition1);
        -webkit-transition: var(--transition1);
        -moz-transition: var(--transition1);
        -ms-transition: var(--transition1);
        -o-transition: var(--transition1);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 2rem;
        -webkit-border-radius: 2rem;
        -moz-border-radius: 2rem;
        -ms-border-radius: 2rem;
        -o-border-radius: 2rem;
    overflow: hidden;
    width: 100%;
    height: 300px;
}

img {
    width: 100%
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio__item p {
    padding-bottom: 2rem;
}

.small {
    color: var(--color-primary-variant)
}

/************* Media Queries (Med) ***************/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


/************* Media Queries (Sm) ***************/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio__item-image {
        overflow: hidden;
        width: 80%;
    }
}