header {
    height: 70vh;
    padding-top: 5rem;
    overflow: hidden;
}


.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*============ CTA =============*/

.cta {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*=========== header socials =========*/

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    position: fixed;
    right: 2rem;
    top: 5rem;
    background: rgba(0, 0, 0, 0.3);
    padding: 2rem 2rem;
    z-index: 2;
    backdrop-filter: blur(15px); 
    border-radius: 3rem;
    width: max-content;
}

.header_socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*=============== Hero image ===================*/

 .me > img{
    background-color: var(--color-bg-primary);
    width: 50rem;
    height: 15rem;
    position: absolute;
    left: 51.75%;
    top: 20rem;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    overflow: auto;
}



/*=============== Scroll Down ===================*/

.scroll_down {
    position: fixed;
    right: 7rem;
    top: 17rem;
    transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
    font-weight: 300;
    font-size: .9rem;    
    background: rgba(0, 0, 0, 0.3);
    padding: 1.7rem 1.7rem;
    gap: 0.8rem;
    z-index: 2;
    border-radius: 3rem;
    backdrop-filter: blur(15px); 
    color: var(--color-primary);
    
}

/*===========Media Queries (Med)=========*/

@media screen and (max-width: 900px) {
    header {
        height: 100vh;
    }

    .header_socials, .scroll_down {
        display: none;
    }

   

}

/*===========Media Queries (Sm)=========*/

@media screen and (max-width: 600px) {
    header{
        height: 50vh;
    }
    .header_socials, .scroll_down {
        display: none;
    }

    .me {
        display: none;
    }
   

}
